/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it
import 'typeface-lato'
import 'typeface-cabin'
import 'typeface-open-sans'
import './src/styles/global.css';